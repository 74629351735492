<template>
	<div v-if="authed">
		<v-data-table id="statoper" :headers="headers" :items="items"
			:footer-props="{ itemsPerPageOptions: [15, 30, 60] }" fixed-header
			:loading="loading" loading-text="加载中...">
			<template v-slot:top>
				<div class="d-flex flex-wrap pt-3 pb-1 pl-1" style="gap:16px">
					<v-menu v-model="menu1" :close-on-content-click="false" :nudge-right="10"
						transition="scale-transition" offset-y min-width="290px">
						<template v-slot:activator="{ on, attrs }">
							<v-text-field v-model="pdate1" label="月份起" readonly v-bind="attrs"
								v-on="on" dense hide-details style="flex: 0 1 auto;max-width:150px;"></v-text-field>
						</template>
						<v-date-picker type="month" locale="zh-TW" v-model="pdate1" @input="menu1=false" scrollable></v-date-picker>
					</v-menu>
					<v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="10"
						transition="scale-transition" offset-y min-width="290px">
						<template v-slot:activator="{ on, attrs }">
							<v-text-field v-model="pdate2" label="月份止" readonly v-bind="attrs"
								v-on="on" dense hide-details style="flex: 0 1 auto;max-width:150px;"></v-text-field>
						</template>
						<v-date-picker type="month" locale="zh-TW" v-model="pdate2" @input="menu2=false" scrollable></v-date-picker>
					</v-menu>
					<v-btn color="primary" @click.stop="fetchData">查询</v-btn>
				</div>
				<v-divider/>
			</template>
			<template v-slot:body.append="{headers}">
				<tr class="sticky-table-footer">
					<td v-for="(header,i) in headers" :key="i">
						<span v-if="header.value === '_id.month'">合计</span>
						<span v-else-if="header.value === '_id.doctor'">{{items.length}}</span>
						<span v-else>{{ items.reduce((acc, cur) => acc + cur[header.value], 0) }}</span>
					</td>
				</tr>
			</template>
		</v-data-table>
	</div>
	<div v-else>
		无此权限
	</div>
</template>

<script>
export default {
	data() {
		return {
			authed: false,
			loading: false,
			headers: [
				{text:'年月', value:'_id.month', width:90},
				{text:'医生', value:'_id.doctor', width:100},
				{text:'胃镜检查量', value:'胃镜检查量', width:110},
				{text:'胃活检人次', value:'胃活检人次', width:110},
				{text:'胃活检块数', value:'胃活检块数', width:110},
				{text:'胃息肉人次', value:'胃息肉人次', width:110},
				{text:'胃息肉块数', value:'胃息肉块数', width:110},
				{text:'肠镜检查量', value:'肠镜检查量', width:110},
				{text:'肠活检人次', value:'肠活检人次', width:110},
				{text:'肠活检块数', value:'肠活检块数', width:110},
				{text:'肠息肉人次', value:'肠息肉人次', width:110},
				{text:'肠息肉块数', value:'肠息肉块数', width:110},
			],
			items: [],
			menu1: false,
			pdate1: new Date().toISOString().substr(0, 7),
			menu2: false,
			pdate2: new Date().toISOString().substr(0, 7),
		}
	},
	created() {
		this.authed = this.$hasPrivilege(['统计']);
		if (!this.authed) return;
	},
	methods: {
		async fetchData() {
			const t1 = new Date(this.pdate1 + '-01T00:00:00+08:00');
			const t2 = new Date(this.pdate2 + '-01T00:00:00+08:00');
			const t3 = new Date(t2.getFullYear(), t2.getMonth()+1, 0, 23, 59, 59);
			this.loading = true;
			try {
				const res = await this.$tcbapp.callFunction({name:"wp2mp",
					data:{
						funcname:'getStatOper',
						data: {
							timeBegin: t1,
							timeEnd: t3,
						}
					}
				});
				this.items = res.result;
			} catch(err) {
				console.error(err);
			}
			this.loading = false;
		},
	},
}
</script>

<style>
#statoper th {
	user-select: auto;
}
.sticky-table-footer td {
  font-weight: bold;
  position: sticky;
  bottom: 0;
  background-color: #f3f3f3;
}
.v-data-table.v-data-table--fixed-header thead th {
  background-color: #f3f3f3!important;
}
</style>