var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.authed)?_c('div',[_c('v-data-table',{attrs:{"id":"statoper","headers":_vm.headers,"items":_vm.items,"footer-props":{ itemsPerPageOptions: [15, 30, 60] },"fixed-header":"","loading":_vm.loading,"loading-text":"加载中..."},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"d-flex flex-wrap pt-3 pb-1 pl-1",staticStyle:{"gap":"16px"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":10,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticStyle:{"flex":"0 1 auto","max-width":"150px"},attrs:{"label":"月份起","readonly":"","dense":"","hide-details":""},model:{value:(_vm.pdate1),callback:function ($$v) {_vm.pdate1=$$v},expression:"pdate1"}},'v-text-field',attrs,false),on))]}}],null,false,2040121385),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{attrs:{"type":"month","locale":"zh-TW","scrollable":""},on:{"input":function($event){_vm.menu1=false}},model:{value:(_vm.pdate1),callback:function ($$v) {_vm.pdate1=$$v},expression:"pdate1"}})],1),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":10,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticStyle:{"flex":"0 1 auto","max-width":"150px"},attrs:{"label":"月份止","readonly":"","dense":"","hide-details":""},model:{value:(_vm.pdate2),callback:function ($$v) {_vm.pdate2=$$v},expression:"pdate2"}},'v-text-field',attrs,false),on))]}}],null,false,584053887),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{attrs:{"type":"month","locale":"zh-TW","scrollable":""},on:{"input":function($event){_vm.menu2=false}},model:{value:(_vm.pdate2),callback:function ($$v) {_vm.pdate2=$$v},expression:"pdate2"}})],1),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.fetchData($event)}}},[_vm._v("查询")])],1),_c('v-divider')]},proxy:true},{key:"body.append",fn:function(ref){
var headers = ref.headers;
return [_c('tr',{staticClass:"sticky-table-footer"},_vm._l((headers),function(header,i){return _c('td',{key:i},[(header.value === '_id.month')?_c('span',[_vm._v("合计")]):(header.value === '_id.doctor')?_c('span',[_vm._v(_vm._s(_vm.items.length))]):_c('span',[_vm._v(_vm._s(_vm.items.reduce(function (acc, cur) { return acc + cur[header.value]; }, 0)))])])}),0)]}}],null,false,1553314369)})],1):_c('div',[_vm._v(" 无此权限 ")])}
var staticRenderFns = []

export { render, staticRenderFns }